<template>
  <div class="page">
    <!-- <div class="template"></div> -->
    <button class="back" @click="back()">
      <img class="back-arrow" src="/img/common/arrow_1_l.png" />
      <div class="back-text">Back to bill</div>
    </button>

    <div class="box">
      <img class="box-email" src="/img/mock/email.png" />
      <div class="action">
        <button class="action-button" @click="clickWebsite()">
          Visit website
        </button>
        <button class="action-button" @click="clickEmail()">Email</button>
        <button class="action-button" @click="clickTel()">Call</button>
      </div>
    </div>

    <div class="tips">Sample Letters shared by your peers</div>
  </div>
</template>

<script>
export default {
  name: "EmailTemplate",
  data: () => {
    return {
      sponsor: {
        name: "",
        avatar: "",
        link: ""
      }
    };
  },
  created() {
    window.scrollTo(0, 0);
    let params = this.$route.params;
    if (params) {
      this.sponsor = params;
    }
  },

  methods: {
    back() {
      this.$router.go(-1);
    },
    clickWebsite() {
      window.open(this.sponsor.link);
    },
    clickEmail() {
      window.open(this.sponsor.email);
    },
    clickTel() {
      window.open("tel:" + this.sponsor.localTel);
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  box-sizing: border-box;
  padding: 0 86px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.back {
  margin: 20px 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &-icon {
    width: 16px;
    height: 14px;
  }

  &-text {
    margin: 0 0 0 12px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;

    color: #527bcc;
  }
}

.box {
  margin: 20px 0 0 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  &-email {
    width: 989.65px;
    height: 1044px;
  }

  .action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-button {
      margin: 0 0 44px 0;
      width: 200px;
      height: 50px;
      background: #cf7872;
      border-radius: 15px;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      text-align: center;
      color: #ffffff;
    }
  }
}

.tips {
  margin: 106px 0 100px 0;
  width: 989.65px;
  height: 111px;
  background: rgba($color: #ffffff, $alpha: 0.5);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 111px;
  text-align: center;
  color: #303030;
}
</style>
