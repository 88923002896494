import axios from "axios";
// import {Message} from 'element-ui';
// import router from '@/router';
import formatUrl from "@/utils/format-url";

// import mock from '@/mock/mock';

const baseURL = "http://api.wetheasians.com";
const instance = axios.create({ baseURL });
// instance.interceptors.request.use((config) => {
//   let user = localStorage.getItem("user");
//   if (user) {
//     user = JSON.parse(user);
//     config.headers["authorization"] = `Bearer ${user.token}`;
//   }
//   return config;
// });
instance.interceptors.response.use(
  (response) => {
    // if(router.currentRoute.name === "trial"){
    //     // 获取mock数据
    //     mock.filter(response)
    // }
    const data = response.data;
    // const {code, message} = data;
    // const noLogin = [
    //     'Home',
    // ].includes(router.currentRoute.name);
    // if (window.location.href.indexOf("/#/signup") === -1
    //     && window.location.href.indexOf("/#/introduction") === -1
    //     && window.location.href.indexOf("/#/mall") === -1
    //     && window.location.href.indexOf("/#/first-page") === -1
    //     && window.location.href.indexOf("/#/link-envelope") === -1
    //     && window.location.href.indexOf("/#/trial") === -1 ) {
    //     if (code === 4000 && !noLogin) {
    //         router.push({
    //             name: 'signin',
    //         });
    //     }
    // }
    // if (code !== 2000 && code !== 4000) {
    //     Message.warning(message);
    // }
    return data;
  },
  (err) => Promise.reject(err)
);
export const get = (url, param = {}, opt = {}) => {
  const u = formatUrl(url, param);
  return instance.get(u, opt);
};
export const post = (url, param = {}, opt = {}) => {
  return instance.post(url, param, opt);
};
