<template>
  <div class="highlights">
    <div class="head">
      <div class="title">Regulations Highlights June 2021</div>
    </div>

    <div class="list">
      <Regulation
        class="regulation"
        v-for="(item, index) in regulations"
        :key="index"
        :tracked="false"
        :regulation="item"
      />
    </div>
  </div>
</template>

<script>
import Regulation from "@/components/regulation";

export default {
  name: "RegulationHighlights",
  components: {
    Regulation
  },
  props: {
    regulations: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data: () => {
    return {};
  },
  methods: {
    clickRegulation() {
      window.open("https://github.com/dancincloud/WetheAsians");
    }
  }
};
</script>

<style scoped lang="scss">
.highlights {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("/img/components/other/bg_highlights.png");
  background-size: cover;

  .head {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .title {
      font-family: Noto Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 30px;
      color: #303030;
    }
  }

  .list {
    margin: 40px 0 0 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .regulation {
      margin: 0 0 50px 0;
    }
  }
}
</style>
