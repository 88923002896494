import { post, get } from "@/utils/http";

export const getBills = async (param = {}) => {
  const url = "/bills/list";
  return get(url, param);
};

export const signup = async (param = {}) => {
  const url = "/signup  ";
  return post(url, param);
};

export const signin = async (param = {}) => {
  const url = "/login  ";
  return post(url, param);
};

export const getMyTracker = async () => {
  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);

    let myTracker = localStorage.getItem(`myTracker_${user.id}`);
    if (myTracker) {
      myTracker = JSON.parse(myTracker);
      return myTracker;
    }
  }

  return [];
  // const url = "/trackedItems";
  // return get(url, param);
};

export const trackItem = async (param = {}, tracked) => {
  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);

    let myTracker = localStorage.getItem(`myTracker_${user.id}`);
    if (myTracker) {
      myTracker = JSON.parse(myTracker);

      if (tracked) {
        myTracker.push(param);
      } else {
        for (let i = 0; i < myTracker.length; i++) {
          if (param.id == myTracker[i].id) {
            myTracker.splice(i, i);
          }
        }
      }
      localStorage.setItem(`myTracker_${user.id}`, JSON.stringify(myTracker));
    } else {
      localStorage.setItem(`myTracker_${user.id}`, JSON.stringify([param]));
    }
  }

  return true;
  // const url = "/trackedItems";
  // return post(url, param);
};
