import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import BillsAndRegulations from "@/views/BillsAndRegulations.vue";
import BillDetail from "@/views/BillDetail.vue";
import MyTracker from "@/views/MyTracker.vue";
import EmptyView from "@/views/EmptyView.vue";
import Signing from "@/views/Signing.vue";
import Onboarding from "@/views/Onboarding.vue";
import EmailTemplate from "@/views/EmailTemplate.vue";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/BillsAndRegulations",
    name: "Bills & Regulations",
    component: BillsAndRegulations
  },
  {
    path: "/BillDetail",
    name: "BillDetail",
    component: BillDetail
  },
  {
    path: "/MyTracker",
    name: "MyTracker",
    component: MyTracker
  },
  {
    path: "/Legislators",
    name: "Legislators",
    component: EmptyView
  },

  {
    path: "/Signin",
    name: "Signin",
    component: Signing
  },
  {
    path: "/Signup",
    name: "Signup",
    component: Signing
  },
  {
    path: "/Onboarding",
    name: "Onboarding",
    component: Onboarding
  },
  {
    path: "/EmailTemplate",
    name: "EmailTemplate",
    component: EmailTemplate
  }
];

const router = new VueRouter({
  routes
});

// router.afterEach(() => {
//   window.scrollTo(0, 0);
// });

export default router;
