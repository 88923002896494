<template>
  <!-- <div class="nav"> -->
  <div
    class="nav-fixed"
    :class="showNav ? 'showNav' : 'hideNav'"
    :style="outstyle"
  >
    <template v-if="mode">
      <button
        v-if="mode == 1"
        class="signing-button"
        @click="clickSigninButton()"
      >
        Sign in
      </button>
      <button
        v-else-if="mode == 2"
        class="signing-button"
        @click="clickSignupButton()"
      >
        Sign up
      </button>
      <button v-else class="nav-back" @click="clickBack()"></button>
      <button class="nav-close" @click="clickClose()"></button>
    </template>
    <template v-else>
      <div class="icon" @click="clickHome()"></div>

      <div class="nav-l">
        <div class="links">
          <button
            class="links-item"
            v-for="(item, i) in links"
            :key="i"
            :class="active == item.name ? 'links-item--active' : ''"
            @click="clickItem(item.path)"
          >
            {{ item.name }}
          </button>
        </div>
        <div v-if="logged" class="account" @click="clickSignout()">
          <div class="account-text">My Account</div>
        </div>
        <button v-if="!logged" class="signin" @click="clickSignin()">
          <img class="signin-icon" src="/img/components/nav/user.png" />
          <div class="signin-text">Sign in</div>
        </button>
      </div>
    </template>
  </div>
  <!-- </div> -->
</template>

<script>
import Bus from "@/bus";

export default {
  name: "navigation",
  props: {
    outstyle: {
      type: String,
      default: ""
    }
  },
  data: () => {
    return {
      showNav: true,
      logged: false,
      links: [
        {
          name: "Bills & Regulations",
          path: "/BillsAndRegulations"
        },
        {
          name: "Legislators",
          path: "/Legislators"
        },
        {
          name: "MyTracker",
          path: "/MyTracker"
        }
      ],
      offset: 0,
      timer: null
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollListener, true);

    this.timer = setInterval(() => {
      this.offset = this.scrollTop();
    }, 100);

    let user = localStorage.getItem("user");
    if (user) {
      this.logged = true;
    } else {
      this.logged = false;
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollListener);

    clearInterval(this.timer);
    this.timer = null;
  },
  watch: {
    "$route"() {
      let user = localStorage.getItem("user");
      if (user) {
        this.logged = true;
      } else {
        this.logged = false;
      }
    }
  },
  computed: {
    active() {
      return this.$route.name;
    },
    mode() {
      if (this.$route.name == "Signup") {
        window.scrollTo(0, 0);
        return 1;
      }

      if (this.$route.name == "Signin") {
        window.scrollTo(0, 0);
        return 2;
      }

      if (this.$route.name == "Onboarding") {
        window.scrollTo(0, 0);
        return 3;
      }

      return 0;
    }
  },
  methods: {
    scrollListener() {
      var scroll = this.scrollTop() - this.offset;
      if (!this.showNav && scroll < 0) {
        this.showNav = true;
        //添加你想要的事件
      } else if (this.showNav && scroll > 0) {
        //添加你想要的事件
        this.showNav = false;
      }
    },
    clickHome() {
      this.$router.push("/");
    },
    clickItem(path) {
      this.$router.push(path);
    },
    scrollTop() {
      return (
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      );
    },
    clickSignin() {
      this.$router.push({
        name: "Signin"
      });
    },
    clickBack() {
      this.$router.go(-1);
    },
    clickClose() {
      this.$router.replace({
        name: "Home"
      });
    },
    clickSigninButton() {
      Bus.$emit("signingModeChange", 1);
    },
    clickSignupButton() {
      Bus.$emit("signingModeChange", 0);
    },
    clickSignout() {
      localStorage.removeItem("user");
      window.location.reload();
    }
  }
};
</script>

<style scoped lang="scss">
.nav {
  position: relative;
  width: 100%;
  height: 83px;
}

.account {
  width: 121px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // background-color: #ffffff;
  border-radius: 53px;

  &-text {
    margin: 0 0 0 10px;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 40px;
    color: #303030;
  }
}

.nav {
  &-back {
    width: 34px;
    height: 30px;
    background-image: url("/img/components/nav/back.png");
    background-size: contain;
    background-repeat: no-repeat;
  }

  &-close {
    width: 30px;
    height: 30px;
    background-image: url("/img/components/nav/close.png");
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.signing-button {
  height: 55px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 55px;
  color: #303030;
}

.nav-fixed {
  z-index: 999;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  padding: 0 86px;
  background-color: rgba(251, 251, 251, 0.8);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .icon {
    width: 137px;
    height: 56px;
    background-image: url("/img/common/logo.png");
  }

  .nav-l {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .links {
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      &-item {
        margin: 0 53px 0 0;
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 25px;
        /* identical to box height */

        color: #303030;

        &--active {
          color: #cf7872;
          font-weight: bold;
        }
      }
    }

    .signin {
      width: 121px;
      height: 40px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
      border: 1px solid #cf7872;
      box-sizing: border-box;
      border-radius: 53px;

      &-icon {
        width: 22px;
        height: 22px;
      }

      &-text {
        margin: 0 0 0 10px;
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: #cf7872;
      }
    }
  }
}

.showNav {
  animation: 500ms ease-in-out 0s normal forwards 1 running fadeInDown;
}

.hideNav {
  animation: 500ms ease-in-out 0s normal forwards 1 running fadeOutUp;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate(0, -100%);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
    transform: none;
  }
  to {
    opacity: 0;
    transform: translate(0, -100%);
  }
}
</style>
