<template>
  <div class="filters">
    <div class="filter" v-for="(filter, index) in filters" :key="index">
      <button
        class="filter-button"
        :class="filter.checked ? 'filter-button--checked' : ''"
        @click="clickFilter(index)"
      >
        {{ filter.title }}
      </button>
      <div v-if="filter.show && filter.menu" class="menu">
        <div
          class="menu-item"
          v-for="(item, i) in filter.menu"
          :key="i"
          @click="clickFilterMenu(index, i)"
        >
          <div
            class="menu-item-checkbox"
            :class="item.checked ? 'menu-item-checkbox--checked' : ''"
          ></div>

          <div class="menu-item-text">{{ item.title }}</div>
        </div>

        <button class="menu-save" @click="clickFilterSave(index)">Save</button>
      </div>
    </div>
    <div class="filter">
      <button class="filter-clear" @click="clickClear()">Clear</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "filters",
  // props: {
  //   filters: {
  //     type: Array,
  //     default: () => {
  //       return [];
  //     }
  //   }
  // },
  data: () => {
    return {
      filters: [
        {
          title: "Bill",
          checked: false,
          show: false
        },
        {
          title: "Regulations",
          checked: false,
          show: false
        },
        {
          title: "Status of Legislation",
          checked: false,
          show: false,
          menu: [
            {
              title: "Introduced",
              checked: false,
              show: false
            },
            {
              title: "Committee consideration",
              checked: false,
              show: false
            },
            {
              title: "Floor consideration",
              checked: false,
              show: false
            },
            {
              title: "Failed one chamber",
              checked: false,
              show: false
            },
            {
              title: "Passed house",
              checked: false,
              show: false
            },
            {
              title: "Passed senate",
              checked: false,
              show: false
            },
            {
              title: "Resolving differences",
              checked: false,
              show: false
            },
            {
              title: "To president",
              checked: false,
              show: false
            },
            {
              title: "Veto actions",
              checked: false,
              show: false
            },
            {
              title: "Became laws",
              checked: false,
              show: false
            }
          ]
        },
        {
          title: "Status of comment",
          checked: false,
          show: false,
          menu: [
            {
              title: "Open to public comment",
              checked: false,
              show: false
            },
            {
              title: "Comment closed",
              checked: false,
              show: false
            }
          ]
        },
        {
          title: "Party",
          checked: false,
          show: false,
          menu: [
            {
              title: "Republican",
              checked: false,
              show: false
            },
            {
              title: "Democratic",
              checked: false,
              show: false
            },
            {
              title: "Independent",
              checked: false,
              show: false
            }
          ]
        }
      ],
      res: {}
    };
  },
  methods: {
    clickFilter(index) {
      this.filters[index].show = !this.filters[index].show;
      if (!this.filters[index].menu) {
        this.filters[index].checked = !this.filters[index].checked;
      }

      if (this.filters[index].show) {
        for (let i in this.filters) {
          if (i != index) this.filters[i].show = false;
        }
      }
    },
    clickFilterMenu(index, i) {
      this.filters[index].menu[i].checked =
        !this.filters[index].menu[i].checked;
    },
    clickFilterSave(index) {
      this.filters[index].checked = false;
      let menu = this.filters[index].menu;
      // console.log(menu);

      for (let i in menu) {
        if (menu[i].checked) {
          this.filters[index].checked = true;
          break;
        }
      }

      this.filters[index].show = false;

      let res = {};
      for (let i = 0; i < this.filters.length; i++) {
        let filter = this.filters[i];
        if (!filter.checked) continue;
        switch (i) {
          case 0:
            i = 1;
            break;
          case 1:
            res["keyword"] = "Regulation";
            break;
          case 2:
            {
              let status = "";
              for (let j in filter.menu) {
                if (filter.menu[j].checked) {
                  status += filter.menu[j].title + ",";
                }
              }

              if (
                status.length > 0 &&
                status.substring(status.length - 1) == ","
              ) {
                status = status.substring(0, status.length - 1);
              }
              res["status"] = status;
            }
            break;
          case 3:
            break;
          case 4:
            {
              let party = "";
              for (let j = 0; j < filter.menu.length; j++) {
                if (filter.menu[j].checked) {
                  switch (j) {
                    case 0:
                      party += "R,";
                      break;
                    case 1:
                      party += "D,";
                      break;
                    case 2:
                      party += "I,";
                      break;
                    default:
                      break;
                  }
                }
              }

              if (
                party.length > 0 &&
                party.substring(party.length - 1) == ","
              ) {
                party = party.substring(0, party.length - 1);
              }

              res["party"] = party;
            }
            break;
          default:
            break;
        }
      }

      if (this.res != res) {
        this.res = res;
        this.$emit("filterResults", res);
      }
    },
    clickClear() {
      this.filters = [
        {
          title: "Bill",
          checked: false,
          show: false
        },
        {
          title: "Regulations",
          checked: false,
          show: false
        },
        {
          title: "Status of Legislation",
          checked: false,
          show: false,
          menu: [
            {
              title: "Introduced",
              checked: false,
              show: false
            },
            {
              title: "Committee consideration",
              checked: false,
              show: false
            },
            {
              title: "Floor consideration",
              checked: false,
              show: false
            },
            {
              title: "Failed one chamber",
              checked: false,
              show: false
            },
            {
              title: "Passed house",
              checked: false,
              show: false
            },
            {
              title: "Passed senate",
              checked: false,
              show: false
            },
            {
              title: "Resolving differences",
              checked: false,
              show: false
            },
            {
              title: "To president",
              checked: false,
              show: false
            },
            {
              title: "Veto actions",
              checked: false,
              show: false
            },
            {
              title: "Became laws",
              checked: false,
              show: false
            }
          ]
        },
        {
          title: "Status of comment",
          checked: false,
          show: false,
          menu: [
            {
              title: "Open to public comment",
              checked: false,
              show: false
            },
            {
              title: "Comment closed",
              checked: false,
              show: false
            }
          ]
        },
        {
          title: "Party",
          checked: false,
          show: false,
          menu: [
            {
              title: "Republican",
              checked: false,
              show: false
            },
            {
              title: "Democratic",
              checked: false,
              show: false
            },
            {
              title: "Independent",
              checked: false,
              show: false
            }
          ]
        }
      ];

      this.res = {};
      this.$emit("filterResults", {});
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.filters {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .filter {
    position: relative;
    margin: 0 18px 0 0;
    &-button {
      padding: 0 15px;
      height: 40px;
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 40px;
      background-color: #ffffff;
      border: 1px solid #c4c4c4;
      box-sizing: border-box;
      border-radius: 15px;
      color: #303030;

      &--checked {
        color: #ffffff;
        background-color: #cf7872;
        border-radius: 15px;
        border: none;
      }
    }

    .menu {
      z-index: 9;
      position: absolute;
      left: 0;
      width: 310px;
      box-sizing: border-box;
      padding: 24px 27px;
      background-color: #ffffff;
      border: 1px solid rgba(48, 48, 48, 0.5);
      box-shadow: 3px 3px 20px rgba(69, 96, 136, 0.3);
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      &-item {
        margin: 0 0 17px 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        &-checkbox {
          width: 20px;
          height: 20px;
          border: 1px solid rgba(48, 48, 48, 0.5);
          border-radius: 5px;
        }

        &-checkbox--checked {
          background-color: #cf7872;
        }

        &-text {
          margin: 0 0 0 28px;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 21px;
          color: #303030;
        }
      }

      &-item:last-child {
        margin: 0 0 0 0;
      }

      &-save {
        align-self: flex-end;
        position: relative;
        width: 78px;
        height: 40px;
        background-color: #303030;
        border: 1px solid rgba(48, 48, 48, 0.5);
        box-sizing: border-box;
        border-radius: 15px;

        font-family: Nunito Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 40px;
        text-align: center;
        color: #ffffff;
      }
    }
  }

  .filter-clear {
    // margin: 0 18px 0 0;
    // margin: 0 0 0 27px;
    width: 72.73px;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 40px;
    color: #303030;
  }

  .filter:last-child {
    margin: 0 0 0 0;
  }
}
</style>
