<template>
  <div id="app" :style="bodystyle">
    <Nav :outstyle="navstyle" />
    <!-- <keep-alive> -->
    <router-view />
    <!-- </keep-alive> -->
    <Footer />
  </div>
</template>

<script>
import Nav from "@/components/nav.vue";
import Footer from "@/components/footer.vue";
// import Bus from "./bus";

export default {
  name: "App",
  data() {
    return {
      bodystyle: "",
      navstyle: ""
    };
  },
  components: {
    Nav,
    Footer
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&family=Nunito+Sans:wght@400;600;700&family=Roboto:wght@400;700&family=Rubik:ital,wght@0,500;1,400&display=swap");

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #fbfbfb;
}

body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  background-color: #fbfbfb;
}

div {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

button {
  cursor: pointer;
  border: none;
  background-color: transparent;
  outline: none;
}

button:hover {
  animation: 200ms ease-in-out 0s normal forwards 1 running buttonHover;
}

@keyframes buttonHover {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.02);
  }
}

// input {
//   height: 45px;
//   box-sizing: border-box;
//   padding: 0 10px;
//   background-color: #ffffff;
//   border: 1px solid #c4c4c4;
//   box-sizing: border-box;
//   border-radius: 10px;
//   font-family: Roboto;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 16px;
//   line-height: 45px;
//   color: #303030;

//   &:focus {
//     outline: none;
//     border: 1px solid #cf7872;
//     box-sizing: border-box;
//     box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
//   }
// }

.page {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
</style>
