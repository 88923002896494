<template>
  <div class="page">
    <div class="info">
      <p class="title">
        Welcome to <span class="title--color">WE</span>theAsians!
      </p>
      <div class="line line-1">
        <div class="line-title">Email</div>
        <input class="line-input" v-model="email" />
      </div>
      <div class="line line-2">
        <div class="line-title">{{ mode ? "Password" : "Set Password" }}</div>
        <input class="line-input" type="password" v-model="pwd" />
      </div>
      <div class="subscribe" v-if="!mode">
        <div
          class="subscribe-checkbox"
          :class="subscribed ? 'subscribe-checkbox--checked' : ''"
          @click="clickSubscribe()"
        ></div>
        <div class="subscribe-text">
          Subscribe to weekly emails with highlights<br />and notifications
        </div>
      </div>

      <button class="confirm" @click="clickConfirm()">
        {{ mode ? "Sign in" : "Sign up" }}
      </button>
      <img class="orline" src="/img/pages/signing/or.png" />
      <button class="third">
        <img class="third-icon" src="/img/pages/signing/google.png" />
        <div class="third-text">
          {{ mode ? "Sign in" : "Sign up" }} with Google
        </div>
      </button>
    </div>
    <img class="cover" src="/img/pages/signing/cover.png" />
  </div>
</template>

<script>
import Bus from "@/bus";
import { signup, signin } from "@/service";

export default {
  name: "Signing",
  data: () => {
    return {
      subscribed: false,
      mode: 0,
      email: "",
      pwd: ""
    };
  },
  created() {
    this.mode = this.$route.name == "Signup" ? 0 : 1;

    Bus.$on("signingModeChange", (mode) => {
      this.mode = mode;
      this.$router.replace({
        name: mode ? "Signin" : "Signup"
      });
    });
  },
  methods: {
    clickSubscribe() {
      this.subscribed = !this.subscribed;
    },
    async clickConfirm() {
      if (this.mode) {
        // sign in
        await this.login();
      } else {
        // sign up
        const data = await signup({
          email: this.email,
          password: this.pwd
        });

        if (data) {
          await this.login();
        }
      }
    },
    async login() {
      const data = await signin({
        email: this.email,
        password: this.pwd
      });
      if (data) {
        localStorage.setItem("user", JSON.stringify(data));

        if (this.mode) {
          this.$router.push({
            name: "Home"
          });
        } else {
          this.$router.push({
            name: "Onboarding"
          });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  box-sizing: border-box;
  padding: 16px 86px 233px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.info {
  // margin: 96px 0 233px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    line-height: 40px;
    color: #303030;

    &--color {
      color: #cf7872;
    }
  }

  .line {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    &-1 {
      margin: 39px 0 0 0;
    }

    &-2 {
      margin: 24px 0 0 0;
    }

    &-title {
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #303030;
    }

    &-input {
      margin: 6px 0 0 0;
      width: 329px;
      height: 45px;
      box-sizing: border-box;
      padding: 0 10px;
      background-color: #ffffff;
      border: 1px solid #c4c4c4;
      box-sizing: border-box;
      border-radius: 10px;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 45px;
      color: #303030;

      &:focus {
        outline: none;
        border: 1px solid #cf7872;
        box-sizing: border-box;
        box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
      }
    }
  }

  .subscribe {
    margin: 18px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    &-checkbox {
      width: 16px;
      height: 16px;
      border: 1px solid rgba(48, 48, 48, 0.5);
      border-radius: 4px;

      &--checked {
        background-color: #cf7872;
      }
    }

    &-text {
      margin: 0 0 0 13px;
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      text-align: left;
      line-height: 20px;
      color: #303030;
    }
  }

  .confirm {
    margin: 29px 0 0 0;
    width: 329px;
    height: 55px;
    background-color: #cf7872;
    border-radius: 15px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 55px;
    color: #ffffff;
  }

  .orline {
    margin: 58px 0 18px 0;
    width: 329px;
    height: 16px;
  }

  .third {
    width: 329px;
    height: 45px;
    background-color: #ffffff;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &-icon {
      width: 22px;
      height: 22px;
    }

    &-text {
      margin: 0 0 0 7px;
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #303030;
    }
  }
}

.cover {
  margin: 0 0 0 145px;
  width: 487px;
  height: 461.7px;
}
</style>
