<template>
  <div class="regulation" :class="tracked ? 'regulation--tracked' : ''">
    <div class="head">
      <div class="title" :style="{ 'color': tracked ? '#ffffff' : '#527BCC' }">
        {{ regulation.title }}
      </div>
      <img
        class="icon"
        :src="
          tracked
            ? '/img/components/regulation/icon_tracked.png'
            : '/img/components/regulation/icon_normal.png'
        "
      />
    </div>
    <div class="line" :style="textStyle">
      <div class="line-title">Agency:</div>
      <div class="line-content">{{ regulation.agency }}</div>
    </div>
    <div class="line" :style="textStyle">
      <div class="line-title">Contact:</div>
      <div class="line-content">{{ regulation.contact }}</div>
    </div>
    <div class="line" :style="textStyle">
      <div class="line-title">Comments <br />due date:</div>
      <div class="line-content">{{ regulation.comments }}</div>
    </div>

    <div v-if="regulation.open" class="status">
      <img class="open-icon" src="/img/components/regulation/open_icon.png" />
      <div class="open-text">OPEN for public comments</div>
    </div>
    <div v-else class="status">
      <img class="close-icon" src="/img/components/regulation/close_icon.png" />
      <div class="close-text">Public comments CLOSED</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "regulation",
  props: {
    regulation: {
      type: Object,
      default: () => {
        return {
          title: "",
          agency: "",
          contact: "",
          comments: "",
          open: false
        };
      }
    },
    tracked: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {};
  },
  computed: {
    percentage() {
      return 50;
    },
    textStyle() {
      return this.tracked ? "color: #ffffff" : "color: #303030";
    }
  },
  methods: {
    openWeb(url) {
      window.open(url);
    },
    clickGitHub() {
      window.open("https://github.com/dancincloud/WetheAsians");
    }
  }
};
</script>

<style scoped lang="scss">
.regulation {
  position: relative;
  width: 333px;
  height: 330px;
  padding: 28px 36px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 20px;
  background-size: cover;

  &:hover {
    animation: 200ms ease-in-out 0s normal forwards 1 running cardHover;
  }

  &--tracked {
    background-image: url("/img/components/regulation/bg_tracked.png");

    &:hover {
      animation: 200ms ease-in-out 0s normal forwards 1 running cardTrackedHover;
    }
  }

  @keyframes cardHover {
    from {
      background: rgba(255, 255, 255, 0.8);
      filter: none;
    }

    to {
      background: rgba(255, 255, 255, 1);
      filter: drop-shadow(0px 10px 20px rgba(204, 132, 128, 0.54));
    }
  }

  @keyframes cardTrackedHover {
    from {
      filter: none;
    }

    to {
      filter: drop-shadow(0px 10px 20px rgba(204, 132, 128, 0.54));
    }
  }

  .head {
    margin: 0 0 22px 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .title {
      width: 280px;
      left: 122px;
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 30px;
      text-align: left;
      color: #ffffff;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .icon {
      width: 37px;
      height: 40px;
    }
  }

  .line {
    margin: 12px 0 0 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    color: #ffffff;

    &-title {
      width: 108px;
      min-width: 108px;
      max-width: 108px;
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      text-align: left;
      line-height: 19px;
      // color: #ffffff;
    }

    &-content {
      // margin: 0 0 0 10px;
      width: auto;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      text-align: left;
      line-height: 19px;
      // color: #ffffff;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .status {
    margin: 43px 0 0 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .open {
    &-icon {
      width: 38px;
      height: 38px;
    }
    &-text {
      margin: 0 0 0 10px;
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;

      color: #ffdc61;
    }
  }

  .close {
    &-icon {
      width: 27px;
      height: 31px;
    }
    &-text {
      margin: 0 0 0 20px;
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;

      color: rgba(69, 96, 136, 0.63);
    }
  }
}
</style>
