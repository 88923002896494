<template>
  <div class="page">
    <div class="title">PAGE COMING SOON...</div>
    <div class="desc">
      Our designers and engineers are working hard<br />to get it delivered to
      you!
    </div>

    <button class="home" @click="clickHome()">Go to homepage</button>
  </div>
</template>

<script>
export default {
  name: "EmptyView",

  methods: {
    clickHome() {
      this.$router.push("/");
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("/img/common/bg_empty.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.title {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  color: #303030;
}

.desc {
  margin: 15px 0 0 0;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 48px;
  text-align: center;
  color: #303030;
}

.home {
  margin: 86px 0 0 0;
  width: 411px;
  height: 66px;
  background: #cf7872;
  border-radius: 20px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 66px;
  color: #ffffff;
}
</style>
