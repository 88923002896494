<template>
  <div class="page">
    <div class="head">
      <div class="intro">
        <div class="intro-title">Make Your Voice Heard</div>
        <div class="intro-desc">
          On the Bills and Regulations <br />Relevant to Your Asian
          <br />Countries of Concern
        </div>
      </div>
      <img class="head-cover" src="/img/pages/home/cover.png" />
    </div>

    <!-- Search -->
    <Search class="search" />

    <!-- My Tracker -->
    <div class="tracker" v-if="myTracker && myTracker.length > 0">
      <div class="tracker-head">
        <div class="tracker-title">My Tracker</div>
        <button class="tracker-more" @click="clickMore()">
          <div class="tracker-more-text">See All Tracked</div>
          <img class="tracker-more-icon" src="/img/common/arrow_r.png" />
        </button>
      </div>
      <Tracker class="tracker-grid" :list="myTracker" />
    </div>

    <!-- Guide -->
    <div class="guide">
      <img class="guide-img-1" src="/img/pages/home/guide_1.png" />
      <div class="guide-content">
        <div class="guide-content-title">
          Know the U.S law-making process and voice your opinion to the
          legislators
        </div>
        <div class="guide-content-desc">
          We have chosen representatives to determine which laws and policies
          will best serve our interests. However, to effectively perform their
          job, legislators rely heavily on input from many different sources.
          They receive technical information from their staffs, state agency
          personnel and professional lobbyists. Yet, much of what they actually
          decide depends on the views, interests and preferences of the citizens
          who elect them.<br /><br />

          We in the process can play a critial role in influcing the
          legislators’ decision-making, and “We the Asians” should strive to
          push acts that are benefitial to the interests of our community from
          bills to laws.
        </div>
        <div class="guide-content-title guide-content-title-2">
          Actively participate in the legislative process in a variety of ways:
        </div>
        <div class="guide-content-desc">
          1. Write a letter to the legislators<br />
          2. Email legislators through government online portal<br />
          3. Visit the office<br />
          4. Attend a Town Hall meeting<br />
          5. Call the legislative hotline<br />
          6. Test before a committee
        </div>
      </div>
    </div>

    <!-- Bill Highlights -->
    <BillHighlights :bills="billHighlights" />

    <!-- Guide -->
    <div class="guide">
      <img class="guide-img-2" src="/img/pages/home/guide_2.png" />
      <div class="guide-content">
        <div class="guide-content-title">
          Know the U.S law-making process and voice your opinion to the
          legislators
        </div>
        <div class="guide-content-desc">
          Each year, regulatory agencies promulgate thousands of important rules
          through a process largely insulated from ordinary citizens. Unelected
          officials at government agencies such as the the U.S. Department of
          Agriculture and the Environmental Protection Agency create thousands
          of regulations that affect nearly every aspect of social and economic
          life hidden from the view of the public. The actions we as residents
          can take is to find out the regulations that are in “open to public
          comments” status and share our thoughts.
        </div>
      </div>
    </div>

    <!-- Bill Highlights -->
    <RegulationHighlights :regulations="regulationHighlights" />
  </div>
</template>

<script>
import Search from "@/components/search";
import Tracker from "@/components/tracker";
import BillHighlights from "@/components/billHighlights";
import RegulationHighlights from "@/components/regulationHighlights";

import { getMyTracker, getBills } from "@/service";

export default {
  name: "Home",
  components: {
    Search,
    Tracker,
    BillHighlights,
    RegulationHighlights
  },
  data: () => {
    return {
      myTracker: [],
      billHighlights: [],
      regulationHighlights: [
        {
          title:
            "[Mock] Determinations of Group Eligibility for MBDA Assistance",
          agency: "Minority Business Development Agency",
          contact: "Kimberly Marcus",
          comments: "Jun 1, 2021",
          open: false
        },
        {
          title:
            "[Mock] Determinations of Group Eligibility for MBDA Assistance",
          agency: "Minority Business Development Agency",
          contact: "Kimberly Marcus",
          comments: "Jun 1, 2021",
          open: true
        },
        {
          title:
            "[Mock] Determinations of Group Eligibility for MBDA Assistance",
          agency: "Minority Business Development Agency",
          contact: "Kimberly Marcus",
          comments: "Jun 1, 2021",
          open: false
        },
        {
          title:
            "[Mock] Determinations of Group Eligibility for MBDA Assistance",
          agency: "Minority Business Development Agency",
          contact: "Kimberly Marcus",
          comments: "Jun 1, 2021",
          open: true
        },
        {
          title:
            "[Mock] Determinations of Group Eligibility for MBDA Assistance",
          agency: "Minority Business Development Agency",
          contact: "Kimberly Marcus",
          comments: "Jun 1, 2021",
          open: false
        },
        {
          title:
            "[Mock] Determinations of Group Eligibility for MBDA Assistance",
          agency: "Minority Business Development Agency",
          contact: "Kimberly Marcus",
          comments: "Jun 1, 2021",
          open: true
        }
      ]
    };
  },
  created() {
    this.getBills();
    this.getMyTracker();
  },
  methods: {
    clickMore() {
      this.$router.push({
        name: "MyTracker"
      });
    },
    async getBills() {
      const data = await getBills();
      if (data.length < 7) {
        this.billHighlights = data;
      } else {
        this.billHighlights = data.slice(0, 6);
      }
    },
    async getMyTracker() {
      const data = await getMyTracker({ id: "60ba4987aace1258c3fe1a34" });
      this.myTracker = data;
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  box-sizing: border-box;
  padding: 0 86px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.head {
  margin: 149px 0 0 0;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .intro {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &-title {
      font-family: Noto Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 48px;
      line-height: 65px;

      color: #303030;
    }

    &-desc {
      margin: 24px 0 0 0;
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      text-align: left;
      line-height: 40px;
      color: #cf7872;
    }
  }

  &-cover {
    // margin: 0 0 0 20px;
    width: 682px;
    height: 212px;
  }
}

.search {
  margin: 102px 0 0 0;
}

.tracker {
  margin: 183px 0 0 0;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-head {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &-title {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 30px;
    color: #303030;
  }

  &-more {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &-text {
      font-family: Noto Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 33px;
      color: #303030;
    }

    &-icon {
      margin: 0 0 0 18px;
      width: 21px;
      height: 21px;
    }
  }

  &-grid {
    margin: 40px 0 0 0;
    width: 100%;
  }
}

// .billHighlights {
//   margin: 166px 0 0 0;
// }

// .regulationHighlights {
//   margin: 166px 0 0 0;
// }

.guide {
  width: 100%;
  box-sizing: border-box;
  padding: 100px 0 50px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &-img-1 {
    margin: 0 135px 50px 0;
    width: 489.04px;
    height: 845px;
  }

  &-img-2 {
    margin: 0 47px 0 0;
    width: 688px;
    height: 625px;
  }

  &-content {
    max-width: 531px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    &-title {
      font-family: Noto Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 41px;
      text-align: left;
      color: #cf7872;

      &-2 {
        margin: 52px 0 0 0;
      }
    }

    &-desc {
      margin: 35px 0 0 0;
      max-width: 564px;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      text-align: left;
      color: #303030;
    }
  }
}
</style>
