<template>
  <div class="tracker-component">
    <template v-for="(item, index) in list">
      <Bill
        class="tracker-component-item"
        v-if="item.tracker"
        :key="index"
        :tracked="true"
        :bill="item"
      />

      <Regulation
        class="tracker-component-item"
        v-else
        :key="index"
        :tracked="true"
        :regulation="item"
      />
    </template>
  </div>
</template>

<script>
import Bill from "@/components/bill";
import Regulation from "@/components/regulation";

export default {
  name: "tracker",
  components: {
    Bill,
    Regulation
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data: () => {
    return {};
  },
  methods: {
    clickBill() {
      window.open("https://github.com/dancincloud/WetheAsians");
    }
  }
};
</script>

<style scoped lang="scss">
.tracker-component {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &-item {
    margin: 0 20px 0 0;

    &:last-child {
      margin: 0 0 0 0;
    }
  }
}
</style>
