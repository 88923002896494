<template>
  <button class="committee" @click="clickCommittee()">
    <div
      class="icon"
      :style="{ backgroundImage: `url('${committee.icon}')` }"
    ></div>
    <div class="name">{{ committee.name }}</div>
  </button>
</template>

<script>
export default {
  name: "committee",
  props: {
    committee: {
      type: Object,
      default: () => {
        return {
          name: "",
          icon: "",
          link: ""
        };
      }
    }
  },
  methods: {
    clickCommittee() {
      window.open(this.committee.link);
    }
  }
};
</script>

<style scoped lang="scss">
.committee {
  width: 130px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  .icon {
    width: 100%;
    height: 130px;
    background-size: contain;
    background-position: bottom;
  }

  .name {
    margin: 12px 0 0 0;
    width: 100%;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #303030;
    word-wrap: break-word;
  }
}
</style>
