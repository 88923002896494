<template>
  <div class="page">
    <div class="head">
      <Filters @filterResults="filterResults" />

      <!-- Search -->
      <div class="search">
        <input
          class="search-content"
          placeholder="Asian hate"
          v-model="keywords"
          @keyup.enter="clickSearch()"
        />
        <button class="search-button" @click="clickSearch()"></button>
      </div>
    </div>

    <div class="list" :class="list.length < 12 ? 'list--short' : ''">
      <template v-for="(item, index) in list">
        <Bill
          class="item"
          v-if="item.tracker"
          :key="index"
          :tracked="false"
          :bill="item"
        />

        <Regulation
          class="item"
          v-else
          :key="index"
          :tracked="false"
          :regulation="item"
        />
      </template>
    </div>
  </div>
</template>

<script>
import Filters from "@/components/filters";
import Bill from "@/components/bill";
import Regulation from "@/components/regulation";

import { getBills } from "@/service";

var keywords = "";

export default {
  name: "BillsAndRegulations",
  components: {
    Filters,
    Bill,
    Regulation
  },
  data: () => {
    return {
      keywords: keywords,
      filters: {},
      list: []
    };
  },
  created() {
    let params = this.$route.params;
    if (params) {
      this.keywords = params.keywords;
    }
    this.init();
  },
  methods: {
    async init() {
      // let params;
      if (this.keywords && this.keywords.length > 0) {
        keywords = this.keywords;
        // params = { keyword: this.keywords.replace(" ", ",") };
        this.filters["keyword"] = this.keywords.replace(" ", ",");
      } else {
        this.filters["keyword"] = "";
      }

      const data = await getBills(this.filters);
      this.list = data;
    },
    clickSearch() {
      this.init();
    },
    filterResults(res) {
      if (res) {
        this.filters = res;
        this.init();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.head {
  width: 100%;
  box-sizing: border-box;
  padding: 0 86px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.list {
  margin: 40px 0 80px 0;
  width: 100%;
  box-sizing: border-box;
  padding: 0 86px;
  background-image: url("/img/components/other/bg_highlights.png");
  background-size: 100% auto;
  background-position: top center;
  background-repeat: repeat-y;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  &--short {
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .item {
    margin: 0 0 50px 0;
  }
}

.search {
  width: 438px;
  box-sizing: border-box;
  padding: 0 10px 0 22px;
  height: 60px;
  border: 1px solid #cf7872;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &:hover {
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  }

  &-content {
    width: 340px;
    height: 40px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: left;
    line-height: 40px;
    color: #303030;
    border: none;
    background-color: rgba(0, 0, 0, 0);
  }

  &-content::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }

  &-content:focus {
    outline: none;
  }

  &-button {
    width: 45px;
    height: 45px;
    background-image: url("/img/pages/home/search_button.png");
    background-size: contain;
    background-repeat: no-repeat;
  }
}
</style>
