<template>
  <div class="search">
    <div class="search-bar">
      <div class="search-head" @click="clickSearchHead()">
        <img class="search-head-icon" src="/img/pages/home/search_icon.png" />
        <div class="search-head-title">Bills & Regulations</div>
        <img
          class="search-head-arrow"
          :class="showChoice ? 'search-head-arrow--up' : ''"
          src="/img/common/arrow_d.png"
        />
        <div class="search-head-line"></div>
      </div>

      <input
        class="search-input"
        placeholder="Search with keyword for bills and regulations"
        v-model="keywords"
        @keyup.enter="clickSearch()"
      />
      <button class="search-button" @click="clickSearch()"></button>
    </div>
    <div class="search-choice" v-if="showChoice">
      <div class="search-choice-item">
        <img
          class="search-choice-item-icon"
          src="/img/pages/home/search_option_1.png"
        />
        <div class="search-choice-item-text">[Mock]Legislators</div>
      </div>
    </div>
  </div>
</template>

<script>
var keywords = "";

export default {
  name: "search",
  data: () => {
    return {
      showChoice: false,
      keywords: keywords
    };
  },
  methods: {
    clickSearchHead() {
      this.showChoice = !this.showChoice;
    },
    clickSearch() {
      keywords = this.keywords;
      this.$router.push({
        name: "Bills & Regulations",
        params: {
          keywords: this.keywords
        }
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.search {
  position: relative;
  width: 1268px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  &-bar {
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px 0 22px;
    height: 70px;
    background-color: #ffffff;
    // box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.3);
    filter: drop-shadow(0px 1px 15px rgba(0, 0, 0, 0.1));
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &:hover {
      outline: none;
      border: 1px solid #cf7872;
      box-sizing: border-box;
      box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
    }
  }

  &-head {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    &-icon {
      width: 30px;
      height: 27px;
    }

    &-title {
      margin: 0 0 0 12px;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: #5269cc;
    }

    &-arrow {
      margin: 0 0 0 10px;
      width: 11px;
      height: 8px;

      &--up {
        transform: rotate(180deg);
      }
    }

    &-line {
      margin: 0 15px;
      width: 1px;
      height: 40px;
      background-color: #000000;
    }
  }

  &-input {
    width: 956px;
    height: 40px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: left;
    line-height: 40px;
    color: #303030;
    border: none;
  }

  &-input::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }

  &-input:focus {
    outline: none;
  }

  &-button {
    width: 53px;
    height: 53px;
    background-image: url("/img/pages/home/search_button.png");
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.search-choice {
  margin: 12px 0 0 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &-item {
    width: 222px;
    height: 61px;
    box-sizing: border-box;
    padding: 0 0 0 22px;
    background-color: #ffffff;
    box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.3);
    border-radius: 70px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &-icon {
      width: 32px;
      height: 32px;
    }

    &-text {
      margin: 0 0 0 15px;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: #5269cc;
    }
  }
}
</style>
