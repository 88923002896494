<template>
  <div class="sponsor-container">
    <button class="sponsor" @mouseover="onMouseover()">
      <div
        class="avatar"
        :style="{ backgroundImage: `url('${sponsor.avatar}')` }"
      ></div>
      <div class="name">{{ sponsor.name }}</div>
    </button>

    <div
      class="card"
      v-if="hover"
      @mouseover="onMouseover()"
      @mouseleave="hover = false"
    >
      <div
        class="avatar"
        :style="{ backgroundImage: `url('${sponsor.avatar}')` }"
      ></div>
      <div class="card-name">{{ sponsor.name }}</div>
      <div class="card-desc">{{ sponsor.desc }}</div>

      <div class="card-action">
        <button class="card-action-button" @click="clickWebsite()">
          Visit website
        </button>
        <button class="card-action-button" @click="clickContact()">
          Contact
        </button>
      </div>
      <div class="card-line">
        <div class="card-line-title">Committee</div>
        <div class="card-line-content">{{ sponsor.committee }}</div>
      </div>
      <div class="card-line">
        <div class="card-line-title">local office</div>
        <div class="card-line-content">{{ sponsor.localOffice }}</div>
      </div>
      <div class="card-line">
        <div class="card-line-title"></div>
        <div class="card-line-content">{{ sponsor.localTel }}</div>
      </div>
      <div class="card-line">
        <div class="card-line-title">DC office</div>
        <div class="card-line-content">{{ sponsor.dcOffice }}</div>
      </div>
      <div class="card-line">
        <div class="card-line-title"></div>
        <div class="card-line-content">{{ sponsor.dcTel }}</div>
      </div>
      <div class="card-line">
        <div class="card-line-title">Party</div>
        <div class="card-line-content">{{ sponsor.party }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "sponsor",
  props: {
    sponsor: {
      type: Object,
      default: () => {
        return {
          name: "",
          avatar: "",
          link: ""
        };
      }
    }
  },
  data: () => {
    return {
      hover: false
    };
  },
  methods: {
    // clickSponsor() {
    //   window.open(this.sponsor.link);
    // },
    onMouseover() {
      if (this.sponsor.email) this.hover = true;
    },
    clickWebsite() {
      // window.open(this.sponsor.link);
      window.open(this.sponsor.link);
    },
    clickContact() {
      this.$router.push({ name: "EmailTemplate", params: this.sponsor });
      // window.open(this.sponsor.email);
    }
  }
};
</script>

<style scoped lang="scss">
.sponsor-container {
  position: relative;

  .avatar {
    position: relative;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: top center;
  }
}

.sponsor {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .name {
    margin: 12px 0 0 0;
    width: 130px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #303030;
  }
}

.card {
  z-index: 9;
  position: absolute;
  top: -22px;
  left: -129.5px;
  width: 398px;
  box-sizing: border-box;
  padding: 22px 25px 17px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(174, 107, 102, 0.63);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &-name {
    margin: 16px 0 6px 0;
    position: relative;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    text-align: center;

    color: #303030;
  }

  &-desc {
    position: relative;

    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;

    color: #303030;
  }

  &-line {
    width: 100%;
    position: relative;
    margin: 18px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &-title {
      position: relative;
      width: 100px;
      font-family: Noto Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #303030;
      text-align: left;
    }

    &-content {
      position: relative;
      margin: 0 0 0 6px;
      max-width: 242px;
      font-family: Noto Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      text-align: left;
      color: #303030;
      word-wrap: break-word;
    }
  }

  &-action {
    margin: 20px 0 0 0;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &-button {
      position: relative;
      width: 168px;
      height: 50px;
      background: #cf7872;
      border-radius: 20px;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 50px;
      text-align: center;
      color: #ffffff;
    }
  }
}
</style>
