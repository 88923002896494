<template>
  <div class="page">
    <div class="head">
      <div class="progress">
        <div class="progress-upper" :style="{ width: percentage + '%' }"></div>
      </div>

      <div class="progress-text">{{ index + 1 }}/{{ steps.length }}</div>
    </div>

    <div class="box">
      <div class="box-title">{{ currentStep.title }}</div>
      <div class="box-desc">{{ currentStep.desc }}</div>

      <template v-if="index == 0">
        <select class="box-select">
          <option value="am">Amharic - አማርኛ</option>
          <option value="ar">Arabic - العربية</option>
          <option value="an">Aragonese - aragonés</option>
          <option value="hy">Armenian - հայերեն</option>
          <option value="zh-CN">Chinese (Simplified) - 中文（简体）</option>
          <option value="zh-TW">Chinese (Traditional) - 中文（繁體）</option>
          <option value="en">English</option>
          <option value="ja">Japanese - 日本語</option>
          <option value="kn">Kannada - ಕನ್ನಡ</option>
          <option value="kk">Kazakh - қазақ тілі</option>
          <option value="km">Khmer - ខ្មែរ</option>
          <option value="ko">Korean - 한국어</option>
          <option value="lo">Lao - ລາວ</option>
          <option value="la">Latin</option>
          <option value="lv">Latvian - latviešu</option>
          <option value="th">Thai - ไทย</option>
          <option value="uz">Uzbek - o‘zbek</option>
          <option value="vi">Vietnamese - Tiếng Việt</option>
          <option value="yo">Yoruba - Èdè Yorùbá</option>
          <option value="zu">Zulu - isiZulu</option>
        </select>
      </template>
      <template v-if="index == 1">
        <div class="box-tags">
          <button
            class="box-tag"
            v-for="(item, index) in currentStep.options"
            :key="index"
            :class="item.checked ? 'box-tag--checked' : ''"
            @click="clickTag(index)"
          >
            {{ item.title }}
          </button>

          <button class="box-tag box-tag-add" @click="clickAdd()">
            + Add new
          </button>
        </div>
      </template>
      <template v-if="index == 2">
        <input class="box-input" placeholder="zipcode" v-model="zipcode" />
      </template>

      <div class="action" v-if="index != 2">
        <button class="action-skip" @click="clickSkip()">Skip</button>
        <button class="action-next" @click="clickNext()">Next</button>
      </div>
      <div class="action" v-else>
        <button class="action-skip" @click="clickStart()">Skip</button>
        <button class="action-next" @click="clickStart()">Get started</button>
      </div>
    </div>
  </div>
</template>

<script>
// import vSelect from "vue-select";

export default {
  name: "Onboarding",
  // components: {
  //   vSelect
  // },
  data: () => {
    return {
      index: 0,
      steps: [
        {
          title: "What is your preferred language?",
          desc: "We can provide translation of legislations in your language!"
        },
        {
          title: "What communities are you interested in?",
          desc: "We will recommend bills and regulations to your countries of interest.",
          options: [
            {
              id: 1,
              title: "Chinese",
              checked: false
            },
            {
              id: 2,
              title: "Indian",
              checked: false
            },
            {
              id: 3,
              title: "Filipino",
              checked: false
            },
            {
              id: 4,
              title: "Vietnamese",
              checked: false
            },
            {
              id: 5,
              title: "Korean",
              checked: false
            },
            {
              id: 6,
              title: "Japanese",
              checked: false
            },

            {
              id: 7,
              title: "Burma",
              checked: false
            }
          ]
        },
        {
          title: "What state do you live in?",
          desc: "We’ll find legislators that represent your state. Residents are especially encouraged to contact representatives of your state or community."
        }
      ],
      zipcode: ""
    };
  },
  computed: {
    percentage() {
      return ((this.index + 1) / 1.0 / this.steps.length) * 100;
    },
    currentStep() {
      return this.steps[this.index];
    }
  },
  methods: {
    clickSkip() {
      this.index++;
    },
    clickNext() {
      this.index++;
    },
    clickStart() {
      this.$router.push({
        name: "Home"
      });
    },
    clickTag(index) {
      this.steps[1].options[index].checked =
        !this.steps[1].options[index].checked;
    },
    clickAdd() {}
  }
};
</script>

<style lang="scss" scoped>
.page {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 0 86px 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.head {
  margin: 63px 0 0 0;
  position: relative;
  width: 839px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .progress {
    position: relative;
    width: 779px;
    height: 14px;
    background-color: #ffffff;
    border: 1px solid #cf7872;
    // box-sizing: border-box;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    &-upper {
      height: 14px;
      background-color: #cf7872;
      border-radius: 20px;
    }

    &-text {
      font-family: Noto Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 27px;
      color: #ab453f;
    }
  }
}

.box {
  width: 100%;
  box-sizing: border-box;
  padding: 0 0 0 215px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &-title {
    margin: 90px 0 0 0;
    max-width: 778px;
    text-align: left;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    color: #303030;
  }

  &-desc {
    margin: 18px 0 0 0;
    max-width: 778px;
    text-align: left;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;

    color: #303030;
  }

  &-select {
    margin: 67px 0 0 0;
    position: relative;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #303030;
    border: none;
    border-bottom: 1px solid #303030;
    background-color: rgba($color: #000000, $alpha: 0);

    &:focus {
      outline: none;
    }
  }

  &-tags {
    margin: 18px 0 0 0;
    max-width: 839px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  &-tag {
    margin: 18px 18px 0 0;
    padding: 0 30px;
    height: 50px;
    border: 1px solid #527bcc;
    box-sizing: border-box;
    border-radius: 60px;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    text-align: center;
    color: #303030;

    &-add {
      border: 2px dashed #527bcc;
    }

    &--checked {
      background: #527bcc;
      color: #ffffff;
    }
  }

  &-input {
    margin: 26px 0 0 0;
    width: 129px;
    height: 50px;
    box-sizing: border-box;
    padding: 0 10px;
    background-color: #ffffff;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #303030;

    &:focus {
      outline: none;
      border: 1px solid #cf7872;
      box-sizing: border-box;
      box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
    }
  }

  .action {
    margin: 120px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    &-skip {
      width: 142px;
      height: 64px;
      border: 1px solid #cf7872;
      box-sizing: border-box;
      border-radius: 20px;
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 64px;
      text-align: center;
      color: #cf7872;
    }

    &-next {
      margin: 0 0 0 26px;
      width: 180px;
      height: 64px;
      background: #cf7872;
      border-radius: 20px;
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 64px;
      text-align: center;
      color: #ffffff;
    }
  }
}
</style>
