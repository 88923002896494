<template>
  <div
    class="bill"
    :class="tracked ? 'bill--tracked' : ''"
    @click="clickBill()"
  >
    <div class="bill-top">
      <div class="head">
        <div
          class="title"
          :style="{ 'color': tracked ? '#ffffff' : '#CF7872' }"
        >
          {{ bill.title }}
        </div>
        <img
          class="icon"
          :src="
            tracked
              ? '/img/components/bill/icon_tracked.png'
              : '/img/components/bill/icon_normal.png'
          "
        />
      </div>
      <div class="line" :style="textStyle">
        <div class="line-title">Sponsor:</div>
        <div class="line-content">{{ bill.sponsorName }}</div>
      </div>
      <div class="line" :style="textStyle">
        <div class="line-title">Committees:</div>
        <div class="line-content">{{ bill.committees }}</div>
      </div>
      <div class="line" :style="textStyle">
        <div class="line-title">Latest <br />Action:</div>
        <div class="line-content">{{ bill.latestAction }}</div>
      </div>
      <!-- <div class="line" :style="textStyle">
      <div class="line-title">Status:</div>
    </div> -->
    </div>

    <div class="status">
      <div class="status-line">
        <div :style="{ width: percentage + '%' }"></div>
        <div class="pointer" :class="tracked ? 'pointer--tracked' : ''">
          <div
            class="pointer-text"
            :class="tracked ? 'pointer-text--tracked' : ''"
          >
            {{ bill.tracker }}
          </div>
          <img
            class="pointer-icon"
            :src="
              tracked
                ? '/img/components/bill/pointer_tracked.png'
                : '/img/components/bill/pointer_normal.png'
            "
          />
        </div>
      </div>

      <div class="progress">
        <div
          class="progress-upper"
          :style="{ width: percentage + '%' }"
          :class="tracked ? 'progress-upper--tracked' : ''"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import Constants from "@/constants.json";

export default {
  name: "bill",
  props: {
    bill: {
      type: Object,
      default: () => {
        return {
          title: "",
          sponsorName: "",
          committees: "",
          latestAction: "",
          tracker: ""
        };
      }
    },
    tracked: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {};
  },
  computed: {
    percentage() {
      for (let i = 0; i < Constants.status.length; i++) {
        let stage = Constants.status[i];
        if (this.bill.tracker.toUpperCase() == stage.toUpperCase()) {
          return (i + 1) * 10.0;
        }
      }

      return 0;
    },
    textStyle() {
      return this.tracked ? "color: #ffffff" : "color: #303030";
    }
  },
  methods: {
    openWeb(url) {
      window.open(url);
    },
    clickBill() {
      this.$router.push({
        name: "BillDetail",
        params: {
          bill: this.bill
        },
        query: {
          id: this.bill.title
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.bill {
  position: relative;
  width: 333px;
  height: 290px;
  padding: 28px 36px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 20px;
  background-size: cover;

  &:hover {
    animation: 200ms ease-in-out 0s normal forwards 1 running cardHover;
  }

  &--tracked {
    background-image: url("/img/components/bill/bg_tracked.png");

    &:hover {
      animation: 200ms ease-in-out 0s normal forwards 1 running cardTrackedHover;
    }
  }

  @keyframes cardHover {
    from {
      background: rgba(255, 255, 255, 0.8);
      filter: none;
    }

    to {
      background: rgba(255, 255, 255, 1);
      filter: drop-shadow(0px 10px 20px rgba(204, 132, 128, 0.54));
    }
  }

  @keyframes cardTrackedHover {
    from {
      filter: none;
    }

    to {
      filter: drop-shadow(0px 10px 20px rgba(204, 132, 128, 0.54));
    }
  }

  &-top {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .head {
    margin: 0 0 22px 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .title {
      width: 260px;
      left: 122px;
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 30px;
      text-align: left;
      color: #ffffff;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .icon {
      width: 49.33px;
      height: 37px;
    }
  }

  .line {
    margin: 12px 0 0 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    color: #ffffff;

    &-title {
      width: 108px;
      min-width: 108px;
      max-width: 108px;
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      text-align: left;
      line-height: 19px;
      // color: #ffffff;
    }

    &-content {
      // margin: 0 0 0 10px;
      width: auto;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      text-align: left;
      line-height: 19px;
      // color: #ffffff;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .status {
    margin: 10px 0 0 0;
    align-self: flex-end;
    justify-self: flex-end;
    position: relative;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    &-line {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .pointer {
      margin: 0 0 0 -50px;
      width: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &-text {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: #ab453f;

        &--tracked {
          color: #ffdc61;
        }
      }

      &-icon {
        width: 8.74px;
        height: 17.5px;
      }
    }

    .progress {
      position: relative;
      width: 100%;
      height: 10px;
      background-color: #ffffff;
      border: 1px solid #cf7872;
      box-sizing: border-box;
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      &--tracked {
        border: 1px solid #ffdc61;
      }

      &-upper {
        height: 8px;
        background-color: #cf7872;
        border-radius: 8px;

        &--tracked {
          background-color: #ffdc61;
        }
      }
    }
  }
}
</style>
