<template>
  <div class="page">
    <!-- Search -->
    <Search class="search" />

    <div class="head">
      <div class="head-title">{{ bill.title }}</div>
      <button
        class="track-button"
        :class="tracked ? 'track-button--done' : ''"
        @click="clickTrack()"
      >
        <img
          class="track-button-icon"
          :class="tracked ? 'track-button--done-icon' : ''"
          :src="
            tracked
              ? '/img/pages/billDetail/track_done.png'
              : '/img/pages/billDetail/track_normal.png'
          "
        />
        <div
          class="track-button-text"
          :class="tracked ? 'track-button--done-text' : ''"
        >
          Track
        </div>
      </button>
    </div>

    <div class="status">
      <div class="status-title">status</div>
      <img class="status-img" :src="'/img/pages/billDetail/status_4.png'" />
    </div>

    <div class="summary">
      <div class="summary-title">Summary</div>
      <div class="summary-content">{{ bill.summary }}</div>
      <button class="summary-fulltext" @click="openFullText()">
        <div class="summary-fulltext-text">Read Full Text</div>
        <img
          class="summary-fulltext-icon"
          src="/img/pages/billDetail/fulltext.png"
        />
      </button>
    </div>
    <div class="translate">
      <button class="translate-lang">
        <div class="translate-text">Chinese(Simplified)</div>
        <img class="translate-lang-icon" src="/img/common/arrow_unfold.png" />
      </button>

      <button class="translate-confirm" @click="showTranslate = !showTranslate">
        <div class="translate-text">Show Translation</div>
        <img
          class="translate-confirm-icon"
          src="/img/pages/billDetail/translate.png"
        />
      </button>
    </div>

    <div class="vote">
      <div class="vote-item" @click="clickAdvocate()">
        <img class="vote-icon" src="/img/pages/billDetail/advocate.png" />
        <div class="vote-text vote-text-v">
          Advocate({{ bill.numSupports }})
        </div>
      </div>
      <div class="vote-item" @click="clickObject()">
        <img class="vote-icon" src="/img/pages/billDetail/object.png" />
        <div class="vote-text vote-text-o">Object({{ bill.numDissents }})</div>
      </div>
    </div>

    <div v-if="showTranslate" class="translate-result"></div>

    <div class="action">
      <div class="person">
        <div class="sponsor">
          <div class="person-title">Sponsor</div>
          <Sponsor :sponsor="sponsor" />
        </div>

        <div class="cosponsors">
          <div class="person-title">Cosponsors</div>
          <div class="cosponsors-grid">
            <Sponsor
              class="cosponsors-grid-item"
              v-for="(item, index) in cosponsors"
              :key="index"
              :sponsor="item"
            />
          </div>
        </div>
      </div>

      <div class="committees">
        <div class="committees-title">Committees</div>
        <div class="committees-grid">
          <Committee
            class="committees-grid-item"
            v-for="(item, index) in committees"
            :key="index"
            :committee="item"
          />
        </div>
      </div>

      <div class="legislators" @click="tipsFold = !tipsFold">
        <div class="legislators-line">
          <div class="legislators-text">Your Legislators</div>
          <img
            v-if="tipsFold"
            class="legislators-icon"
            src="/img/pages/billDetail/tips.png"
          />
        </div>

        <div v-if="!tipsFold" class="legislators-desc">
          Residents are encouraged to contact legislators in their districts.
          Online email via legislators’ website will verify zipcode and address
          to determine your residency before allowing you to send email.
        </div>
      </div>

      <div class="legislators-grid">
        <Sponsor
          class="legislators-grid-item"
          v-for="(item, index) in legislators"
          :key="index"
          :sponsor="item"
        />
      </div>
    </div>

    <div class="comments"></div>
  </div>
</template>

<script>
import Search from "@/components/search";
import Sponsor from "@/components/sponsor";
import Committee from "@/components/committee";

import { trackItem } from "@/service";

let bill = {};

export default {
  name: "BillDetail",
  components: {
    Search,
    Sponsor,
    Committee
  },
  data: () => {
    return {
      id: 1,
      bill: bill,
      // title: "H.R.1083 - Southeast Asia Strategy Act",
      tracked: false,
      // status: "Introduced",
      // link: "https://www.congress.gov/bill/117th-congress/house-bill/1083?r=1&s=4",
      // summary:
      //   "Shown Here:\nPassed House (04/19/2021)\nSoutheast Asia Strategy Act\nThis bill directs the Department of State to submit to Congress a strategy for engagement with Southeast Asia and the Association of Southeast Asian Nations (ASEAN). The strategy shall include various elements including (1) an identification of enduring U.S. interests in the region and efforts to bolster ASEAN's effectiveness; (2) a list of ongoing and planned initiatives to strengthen U.S. relationships in the region, including efforts to promote inclusive economic growth and energy innovation; and (3) a summary of ongoing efforts to promote human rights and democracy and strengthen the rule of law.",
      // advocate: 127,
      // object: 8,
      sponsor: {
        name: "Rep. Gerald E. Connolly",
        avatar: "/img/mock/user_1.png",
        link: "https://wagner.house.gov/"
      },
      cosponsors: [
        {
          name: "Rep. David E. Price",
          avatar: "/img/mock/user_2.png",
          link: "https://wagner.house.gov/"
        },
        {
          name: "Rep. Vern Buchanan",
          avatar: "/img/mock/user_3.png",
          link: "https://wagner.house.gov/"
        },
        {
          name: "Rep. Brian K. Fitzpatrick",
          avatar: "/img/mock/user_4.png",
          link: "https://wagner.house.gov/"
        },
        {
          name: "Rep. Dina Titus",
          avatar: "/img/mock/user_5.png",
          link: "https://wagner.house.gov/"
        }
      ],
      committees: [
        {
          name: "House Foreign Affairs",
          icon: "/img/mock/c_1.png",
          link: "https://foreignaffairs.house.gov/"
        },
        {
          name: "Senate Foreign Relations",
          icon: "/img/mock/c_2.png",
          link: "https://foreignaffairs.house.gov/"
        }
      ],
      legislators: [
        {
          name: "Rep. Mike Doyle",
          avatar: "/img/mock/user_8.png",
          link: "https://doyle.house.gov/",
          desc: "US House since 1999",
          committee: "Health - Democratic Chair Rules",
          localOffice: "2637 East Carson Street Pittsburgh, PA 15203",
          localTel: "(412) 390-1499",
          dcOffice: "270 Cannon HOB Washington, DC 20515",
          dcTel: "(202) 225-2135",
          party: "Democratic",
          email:
            "https://doyle.house.gov/zip-code-lookup?form=/contact/email-me"
        },
        {
          name: "Senate Jay Costa",
          avatar: "/img/mock/user_7.png",
          link: "https://wagner.house.gov/",
          desc: "PA House since 1999",
          committee: "Health - Democratic Chair Rules",
          localOffice: "2345 Murray Avenue, Suite 205, Pittsburgh, PA 15217",
          localTel: "(412) 422-1774",
          dcOffice:
            "332 Main Capitol Building, P.O. Box 202023, Harrisburg, PA 17120-2023",
          dcTel: "(717) 705-1875",
          party: "Democratic",
          email: "31241324"
        }
      ],
      tipsFold: true,
      showTranslate: false
    };
  },
  created() {
    window.scrollTo(0, 0);
    let params = this.$route.params;
    if (params && params.bill) {
      this.bill = params.bill;
      bill = this.bill;
    }
  },
  methods: {
    async clickTrack() {
      let user = localStorage.getItem("user");
      if (!user) {
        this.$router.push({
          name: "Signin"
        });
        return;
      }

      this.tracked = !this.tracked;

      await trackItem(this.bill, this.tracked);
    },
    openFullText() {
      // window.open(this.link);
      window.open(
        "https://www.congress.gov/bill/117th-congress/house-bill/1112/text?q=%7B%22search%22%3A%5B%22-+Protect+Democracy+in+Burma+Act+of+2021%22%5D%7D&r=1&s=6"
      );
    },
    clickAdvocate() {
      this.bill.numSupports++;
    },
    clickObject() {
      this.bill.numDissents++;
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  box-sizing: border-box;
  padding: 0 86px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.search {
  margin: 20px 0 0 0;
}

.head {
  margin: 85px 0 0 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &-title {
    max-width: 1090px;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 49px;
    color: #303030;
    text-align: left;
  }

  .track-button {
    margin: 0 0 0 56px;
    width: 110px;
    height: 40px;
    filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.2));
    border-radius: 20px;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &-icon {
      width: 22px;
      height: 20px;
    }

    &-text {
      margin: 0 0 0 9px;
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      color: #303030;
    }

    &--done {
      background-color: #527bcc;

      &-icon {
        width: 15px;
        height: 17px;
      }

      &-text {
        margin: 0 0 0 8px;
        color: #ffffff;
      }
    }
  }
}

.status {
  margin: 43px 0 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &-title {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    color: #303030;
  }

  &-img {
    margin: 23px 0 0 0;
    width: 100%;
    height: auto;
  }
}

.summary {
  position: relative;
  margin: 80px 0 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &-title {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    color: #303030;
  }

  &-content {
    margin: 33px 0 0 0;
    width: 100%;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    text-align: left;
    white-space: pre-wrap;
    line-height: 30px;
    color: #303030;
  }

  &-fulltext {
    position: absolute;
    top: 0;
    right: 0;
    width: 208px;
    height: 47px;
    background-color: #ffffff;
    border: 1px solid #cf7872;
    border-radius: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &-text {
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 25px;
      color: #303030;
    }

    &-icon {
      margin: 0 0 0 18px;
      width: 24px;
      height: 24px;
    }
  }
}

.translate {
  margin: 33px 0 0 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &-text {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    color: #303030;
  }

  &-lang {
    width: 230px;
    height: 47px;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
    border-radius: 40px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &-icon {
      margin: 0 0 0 39px;
      width: 24px;
      height: 24px;
    }
  }

  &-confirm {
    margin: 0 0 0 29px;
    width: 232px;
    height: 47px;
    background-color: #ffffff;
    border: 1px solid #cf7872;
    box-sizing: border-box;
    border-radius: 40px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &-icon {
      margin: 0 0 0 17px;
      width: 24px;
      height: 24px;
    }
  }

  &-result {
    width: 1268px;
    height: 230px;
    background-image: url("/img/mock/translate.png");
  }
}

.vote {
  margin: 25px 0 43px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  &-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &-item:last-child {
    margin: 0 0 0 41px;
  }

  &-icon {
    width: 42.7px;
    height: 40px;
  }

  &-text {
    margin: 0 0 0 10px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;

    &-v {
      color: #527bcc;
    }

    &-o {
      color: #cf7872;
    }
  }
}

.action {
  margin: 50px 0 127px 0;
  width: 100%;
  box-sizing: border-box;
  padding: 76px 90px 67px;
  background-color: rgba($color: #ede4e3, $alpha: 0.5);
  box-shadow: 0px 0px 20px rgba(69, 87, 136, 0.8);
  border-radius: 20px;
}

.person {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  &-title {
    margin: 0 0 24px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #606060;
  }

  .sponsor {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .cosponsors {
    margin: 0 0 0 140px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &-grid {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;

      &-item {
        margin: 0 92px 0 0;
      }

      &-item:last-child {
        margin: 0 0 0 0;
      }
    }
  }
}

.committees {
  margin: 75px 0 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #606060;
  }

  &-grid {
    margin: 23px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &-item {
      margin: 0 78px 0 0;
    }

    &-item:last-child {
      margin: 0 0 0 0;
    }
  }
}

.legislators {
  margin: 74px 0 58px 0;
  position: relative;
  width: 100%;
  padding: 16px 0;
  background: #f8f8f8;
  border-radius: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-line {
    height: 33px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &-text {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    color: #ab453f;
  }

  &-icon {
    margin: 0 0 0 24px;
    width: 25px;
    height: 25px;
  }

  &-desc {
    margin: 12px 0 10px 0;
    width: 956px;

    font-family: Noto Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    text-align: left;
    color: #303030;
  }

  &-grid {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &-item {
      margin: 0 92px 0 0;
    }

    &-item:last-child {
      margin: 0 0 0 0;
    }
  }
}

.comments {
  margin: 0 0 200px 0;
  width: 100%;
  height: 622px;
  background-image: url("/img/mock/comments_1.png");
}
</style>
