<template>
  <div class="page">
    <!-- Panel -->
    <!-- <div class="title">{{ myTracker.length }} Tracking Cases</div> -->
    <div class="panel-bg"></div>
    <div v-if="myTracker && myTracker.length > 0" class="panel">
      <template v-for="(item, index) in summary">
        <button
          v-if="item.num > 0"
          class="panel-item"
          :key="index"
          @click="clickCatalog(index)"
        >
          <div class="panel-item-line">
            <div
              class="panel-item-dot"
              :style="{ backgroundColor: item.color }"
            ></div>
            <div
              class="panel-item-num"
              :class="item.active ? 'panel-item--active' : ''"
            >
              {{ item.num }}
            </div>
          </div>
          <div
            class="panel-item-title"
            :class="item.active ? 'panel-item--active' : ''"
          >
            {{ item.title }}
          </div>
        </button>
      </template>
    </div>
    <div v-else class="panel-empty">
      <div class="panel-empty-line">
        <div class="panel-empty-line-title">0 Tracking Cases</div>
      </div>

      <div class="panel-empty-box">
        <img class="panel-empty-cover" src="/img/pages/myTracker/cover.png" />
        <div class="panel-empty-content">
          <div class="panel-empty-title">Hello, friend!</div>
          <div class="panel-empty-desc">
            You haven’t tracked any bills or regulations yet. Catch up on U.S.
            politics and make your voice heard!
          </div>
          <button class="panel-empty-button" @click="clickBR()">
            Explore Bills & Regulations
          </button>
        </div>
      </div>
    </div>
    <!-- My Tracker -->
    <template v-if="myTracker && myTracker.length > 0">
      <div class="title">{{ showStatus }}</div>
      <Tracker class="tracker" :list="sublist" />
    </template>

    <!-- Bill Highlights -->
    <BillHighlights class="billHighlights" :bills="billHighlights" />
  </div>
</template>

<script>
import Tracker from "@/components/tracker";
import BillHighlights from "@/components/billHighlights";
// import RegulationHighlights from "@/components/regulationHighlights";
import Constants from "@/constants.json";

import { getMyTracker, getBills } from "@/service";

let summary = [
  {
    title: "All Cases",
    num: 0,
    active: true,
    color: "#3A9FFD"
  }
];
for (let i = 0; i < Constants.status.length; i++) {
  summary[i + 1] = {
    title: Constants.status[i],
    num: 0,
    active: false,
    color: i > 9 ? "#0EC055" : "#FF9797"
  };
}

export default {
  name: "MyTracker",
  components: {
    Tracker,
    BillHighlights
    // RegulationHighlights
  },
  data: () => {
    return {
      summary: summary,
      myTracker: [],
      billHighlights: []
    };
  },
  created() {
    this.getBills();
    this.getMyTracker();
  },
  computed: {
    showStatus() {
      for (let i in this.summary) {
        if (this.summary[i].active) {
          return this.summary[i].title;
        }
      }

      return "";
    },
    sublist() {
      let myTracker = this.myTracker;
      for (let i in this.summary) {
        if (this.summary[i].active) {
          if (i == 0) return myTracker;

          if (i == 11) {
            return myTracker.filter((item) => {
              return item.open;
            });
          }

          if (i == 12) {
            return myTracker.filter((item) => {
              return item.open == false;
            });
          }

          let ans = [];

          for (let j in myTracker) {
            let item = myTracker[j];
            if (
              item.tracker &&
              item.tracker.toUpperCase() == this.summary[i].title.toUpperCase()
            ) {
              ans.push(item);
            }
          }

          return ans;
        }
      }

      return [];
    }
  },
  methods: {
    summarize(trackers) {
      let summary = this.summary;
      summary.forEach((item) => {
        item.num = 0;
      });

      trackers.forEach((item) => {
        if (item.tracker) {
          // Bill
          summary.forEach((stage) => {
            if (item.tracker.toUpperCase() == stage.title.toUpperCase()) {
              stage.num++;
            }
          });
        } else {
          if (item.open) {
            summary[11].num++;
          } else {
            summary[12].num++;
          }
        }
      });
      summary[0].num = trackers.length;

      this.summary = summary;
    },
    clickCatalog(index) {
      for (let i in this.summary) {
        this.summary[i].active = false;
      }

      this.summary[index].active = true;
    },
    clickBR() {
      this.$router.push({
        name: "Bills & Regulations"
      });
    },
    async getBills() {
      const data = await getBills();
      if (data.length < 7) {
        this.billHighlights = data;
      } else {
        this.billHighlights = data.slice(0, 6);
      }
    },
    async getMyTracker() {
      const data = await getMyTracker({ id: "60ba4987aace1258c3fe1a34" });
      this.summarize(data);
      this.myTracker = data;
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  box-sizing: border-box;
  padding: 0 86px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.title {
  margin: 33px 0 0 0;
  width: 100%;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  text-align: left;
  line-height: 33px;
  color: #303030;
}

.panel-bg {
  position: absolute;
  top: 140px;
  left: 0;
  width: 275px;
  height: 616px;
  background-image: url("/img/common/bg_l.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.panel-empty {
  margin: 20px 0 0 0;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 28px 36px 77px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba($color: #ffffff, $alpha: 0.5);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
  border-radius: 20px;

  &-line {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &-title {
      font-family: Noto Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 33px;
      color: #303030;
    }
  }

  &-box {
    margin: 47px 0 0 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &-cover {
    position: relative;
    width: 358.09px;
    height: 322.57px;
  }

  &-content {
    margin: 0 0 0 166px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  &-title {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    color: #303030;
  }

  &-desc {
    margin: 23px 0 0 0;
    max-width: 452px;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #303030;
    text-align: left;
  }

  &-button {
    margin: 51px 0 0 0;
    width: 300px;
    height: 55px;
    background: #cf7872;
    border-radius: 20px;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #ffffff;
  }
}

.panel {
  margin: 26px 0 0 0;
  width: 100%;
  box-sizing: border-box;
  padding: 0 25px;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  &-item {
    margin: 0 40px 0 0;
    width: 160px;
    height: 200px;
    box-sizing: border-box;
    padding: 47px 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    &:last-child {
      margin: 0 0 0 0;
    }

    &-line {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    &-dot {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #3a9ffd;
    }

    &-num {
      margin: 0 0 0 15px;
      font-family: Noto Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 48px;
      line-height: 65px;
      color: #5269cc;
    }

    &-title {
      margin: 5px 0 0 0;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 28px;
      color: #303030;
    }

    &--active {
      color: #ab453f;
    }
  }
}

.tracker {
  margin: 28px 0 0 0;
}

.billHighlights {
  margin: 150px 0 0 0;
}
</style>
